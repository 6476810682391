import { VariableChanges, VariablesState } from "../interfaces/PlayPlanInterface";

class VariablesStore {
    private _variables_change_timeline: Record<number, VariableChanges>;
    private _populated_timestamps: number[];
    private _last_compiled_pointer: number;
    private _compile_cache?: VariablesState;

    public constructor() {
        this._variables_change_timeline = {};
        this._populated_timestamps = [];
        this._last_compiled_pointer = -1;
        this._compile_cache = undefined;
    }

    public reset() {
        this._variables_change_timeline = {};
        this._populated_timestamps = [];
        this._last_compiled_pointer = -1;
        this._compile_cache = undefined;
    }

    private add_to_timeline(timestamp: number, variable_changes: VariableChanges) {
        if (!(timestamp in this._variables_change_timeline)) {
            this._variables_change_timeline[timestamp] = {};
        }

        for (const var_name in variable_changes) {
            this._variables_change_timeline[timestamp][var_name] = variable_changes[var_name];
        }
    }
    public add_change(timestamp: number, variable_changes: VariableChanges) {
        this.add_to_timeline(timestamp, variable_changes);
        this._populated_timestamps.push(timestamp);

        const last_elem_index = this._populated_timestamps.length - 1;

        if (
            this._populated_timestamps.length > 1 &&
            this._populated_timestamps[last_elem_index - 1] > this._populated_timestamps[last_elem_index]
        ) {
            this._populated_timestamps.sort();
        }
    }

    public compile(timestamp: number): VariablesState {
        if (this._populated_timestamps.length === 0) {
            return {};
        }

        this._last_compiled_pointer = Math.max(this._last_compiled_pointer, -1);
        if (this._last_compiled_pointer >= this._populated_timestamps.length) {
            this._last_compiled_pointer = -1;
        }

        if (this._last_compiled_pointer > timestamp) {
            this._compile_cache = {};
        }

        let res: VariablesState = { ...this._compile_cache };

        while (
            this._last_compiled_pointer < this._populated_timestamps.length - 1 &&
            this._populated_timestamps[this._last_compiled_pointer + 1] <= timestamp
        ) {
            if (!this._compile_cache) {
                this._compile_cache = {};
            }

            const timestamp_to_apply = this._populated_timestamps[this._last_compiled_pointer + 1];

            if (timestamp_to_apply in this._variables_change_timeline) {
                const to_apply = this._variables_change_timeline[timestamp_to_apply];
                res = this._apply(this._compile_cache, to_apply);
                this._compile_cache = res;
            }
            this._last_compiled_pointer = Math.max(this._last_compiled_pointer + 1, 0);
        }

        return res;
    }

    public _apply(prev: VariablesState, to_apply: VariableChanges): VariablesState {
        const res = { ...prev };

        for (const var_name in to_apply) {
            const value = to_apply[var_name];
            if (value && typeof value === "object" && value.signal === "DELETE") {
                delete res[var_name];
            } else if (value && typeof value === "object" && value.signal === "OBJECT") {
                res[var_name] = value.object_repr;
            } else {
                res[var_name] = value;
            }
        }
        return res;
    }
}

export default VariablesStore;
