import React, { FC, useState } from "react";
import { codeContext } from "./code-context";

interface CodeContextProviderProps {
    children: React.ReactNode;
}
const CodeContextProvider: FC<CodeContextProviderProps> = ({ children }) => {
    const [code, setCode] = useState("");
    return <codeContext.Provider value={{ code, setCode }}>{children}</codeContext.Provider>;
};

export default CodeContextProvider;
