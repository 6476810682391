import { ErrorResponse } from "../interfaces/Response";
import { PlayPlanAction } from "../interfaces/PlayPlanInterface";
import PlayPlanProducerInterface from "../interfaces/PlayPlanProducerInterface";
import PlayPlanSubscriberInterface from "../interfaces/PlayPlanSubscriberInterface";

class PlanPublisher implements PlayPlanProducerInterface {
    private _subs: PlayPlanSubscriberInterface[];
    private _plan: PlayPlanAction[];

    constructor() {
        this._subs = [];
        this._plan = [];
    }

    public receive(plan: PlayPlanAction[]) {
        this._plan = [...this._plan, ...plan];
        this.publish();
    }

    public publish() {
        for (const sub of this._subs) {
            sub.notify(this._plan);
        }
    }

    public reset() {
        this._plan = [];
    }

    public registerSubscriber(sub: PlayPlanSubscriberInterface) {
        this._subs.push(sub);
    }

    public errorHandler(err: ErrorResponse) {
        for (const sub of this._subs) {
            sub.errorHandler?.(err);
        }
    }
}

export default PlanPublisher;
