import React, { ButtonHTMLAttributes, FC } from "react";
import styles from "./Button.module.scss";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

const Button: FC<ButtonProps> = ({ className, children, ...props }) => {
    const classNames = (className || "") + ` ${styles.Button}`;

    return (
        <button className={classNames} {...props}>
            {children}
        </button>
    );
};

export default Button;
