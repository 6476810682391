import { FC } from "react";
import Button from "../UI/Button/Button";
import styles from "./RunButton.module.scss";
import { ColorRing } from "react-loader-spinner";

interface RunButtonProps {
    onRun: () => void;
    isLoading: boolean;
}

const RunButton: FC<RunButtonProps> = (props) => {
    return (
        <>
            {!props.isLoading && (
                <Button onClick={props.onRun} className={styles.RunButton}>
                    ► Run
                </Button>
            )}

            {props.isLoading && (
                <Button className={styles.LoaderButton}>
                    <ColorRing
                        visible={true}
                        height="28.7"
                        width="28.7"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{}}
                        wrapperClass="color-ring-wrapper"
                        colors={["black", "black", "black", "black", "black"]}
                    />
                </Button>
            )}
        </>
    );
};

export default RunButton;
