class ConnectionHandler {
    private _host: string;
    private _is_sending: boolean;
    private _latest_request_id?: string;
    private _isLoadingSetter?: (isLoading: boolean) => void;

    public constructor(host: string) {
        this._host = host;
        this._is_sending = false;
    }

    public setIsLoadingSetter(isLoadingSetter: (isLoading: boolean) => void) {
        this._isLoadingSetter = isLoadingSetter;
    }

    public async send(request: any) {
        if (!this._is_sending) {
            this._is_sending = true;
            this._isLoadingSetter?.(true);

            try {
                const response = await fetch(`${this._host}/run`, {
                    body: JSON.stringify(request),
                    method: "POST",
                    mode: "cors",
                    headers: { "Content-Type": "application/json" },
                });
                const data = await response.json();
                this._is_sending = false;
                this._isLoadingSetter?.(false);
                if (response.ok && data) {
                    return data;
                } else if (data) {
                    // this.errorHandler(JSON.parse(data.detail));
                } else {
                    // this.errorHandler({ line_number: 1, message: "Unexpected error has occurred." });
                }
            } catch (e) {
                this._is_sending = false;
                this._isLoadingSetter?.(false);
                // this._runningStopper?.();
                // this.errorHandler({ line_number: 1, message: "Network error. Can't connect to the server." });
            }
        }
    }
}

export default ConnectionHandler;
