import React, { FC, ReactNode, useEffect, useState } from "react";
import StructureClient from "../../../debug/structures/structure-clients/StructureClient";
import styles from "./VisualizerKeeper.module.scss";

interface VisualizerKeeperProps {
    structureClients: StructureClient[];
    onClientAdd: (client: StructureClient) => void;
    refresh: () => void;
}

interface VisualizerProps {
    structureClient: StructureClient;
    style?: React.CSSProperties;
    className?: string;
}

const VisualizerKeeper: FC<VisualizerKeeperProps> = ({ onClientAdd, refresh, ...props }) => {
    useEffect(() => {
        window.addEventListener("structureCreation", (e) => {
            e.stopPropagation();
            e.stopImmediatePropagation();
            const { detail: new_structure_client } = e as CustomEvent<StructureClient>;
            onClientAdd(new_structure_client);
        });
    }, [onClientAdd]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const components = props.structureClients.map((structureClient) => (
        <Visualizer key={structureClient.id} structureClient={structureClient} />
    ));

    return (
        <div className={styles.VisualizerKeeper}>
            <div className={styles.Heading}>Visualization</div>
            <div className={styles.VisualizersWrapper}>{components}</div>
        </div>
    );
};

const Visualizer: FC<VisualizerProps> = ({ structureClient }) => {
    const [content, setContent] = useState<ReactNode>(null);
    useEffect(() => {
        structureClient.setVisualStateSetter(setContent);
    }, [structureClient]);

    if (!content) {
        return <></>;
    }

    return <div className={styles.Visualizer}>{content}</div>;
};

export default VisualizerKeeper;
