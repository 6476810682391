import { useCallback, useContext, useEffect, useState } from "react";
import { BACKEND_URL } from "../constants";
import ConnectionHandler from "../run/connection-handler/ConnectionHandler";
import { codeContext } from "../context/code-context/code-context";
import { TestCaseResults } from "../run/interface/interfaces";
import { Problem } from "../problems/interfaces";

const connectionHandler = new ConnectionHandler(BACKEND_URL);

export const useRun = () => {
    const codeCtx = useContext(codeContext);
    const [isLoading, setIsLoading] = useState(false);
    const [testCaseResults, setTestCaseResults] = useState<TestCaseResults | undefined>(undefined);

    useEffect(() => {
        connectionHandler.setIsLoadingSetter(setIsLoading);
    }, []);

    const run = async (problem: Problem) => {
        const test_case_results = await connectionHandler.send({
            code: codeCtx.code,
            test_cases: problem.test_cases,
            problem: problem.id,
        });
        setTestCaseResults(test_case_results);
    };

    const resetTestResults = useCallback(() => {
        setTestCaseResults(undefined);
    }, []);

    return { run, isLoading, testCaseResults, resetTestResults };
};
