import { ErrorResponse } from "../interfaces/Response";
import { PlayPlanAction } from "../interfaces/PlayPlanInterface";
import PlanPublisher from "../plan-publisher/PlanPublisher";

class ConnectionHandler {
    private _plan_publisher: PlanPublisher;
    private _host: string;
    private _is_sending: boolean;
    private _isLoadingSetter?: (isLoading: boolean) => void;
    private _latest_request_id?: string;
    private _runningStopper?: () => void;

    public constructor(plan_publisher: PlanPublisher, host: string) {
        this._plan_publisher = plan_publisher;
        this._host = host;
        this._is_sending = false;
    }

    public setRunningStopper(running_stopper: () => void) {
        this._runningStopper = running_stopper;
    }

    public setIsLoadingSetter(isLoadingSetter: (isLoading: boolean) => void) {
        this._isLoadingSetter = isLoadingSetter;
    }

    public async send(request: any) {
        if (!this._is_sending) {
            this._is_sending = true;
            this._isLoadingSetter?.(true);
            try {
                const response = await fetch(`${this._host}/debug`, {
                    body: JSON.stringify({ ...request, request_id: this._latest_request_id }),
                    method: "POST",
                    mode: "cors",
                    headers: { "Content-Type": "application/json" },
                });
                const data = await response.json();
                this._is_sending = false;
                this._isLoadingSetter?.(false);
                if (response.ok && data) {
                    this.receive(data);
                    // } else if (data) {
                    //     this._runningStopper?.();
                    //     this.errorHandler(JSON.parse(data.detail));
                } else {
                    this._runningStopper?.();
                    this.errorHandler({ line_number: 1, message: "Unexpected error has occurred." });
                }
            } catch (e) {
                this._is_sending = false;
                this._isLoadingSetter?.(false);
                this._runningStopper?.();
                this.errorHandler({ line_number: 1, message: "Network error. Can't connect to the server." });
            }
        }
    }

    private errorHandler(error: ErrorResponse) {
        this._plan_publisher.errorHandler(error);
    }

    public receive(plan: PlayPlanAction[]) {
        this._is_sending = false;
        this._plan_publisher.receive(plan);
    }
}

export default ConnectionHandler;
