import { FC, useContext, useEffect, useRef, useState } from "react";
import styles from "./CodeEditor.module.scss";
import CodeMirror, { ReactCodeMirrorRef } from "@uiw/react-codemirror";
import { python } from "@codemirror/lang-python";
// import Navbar from "../Navbar/Navbar";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";
import {
    RunHighlightDefinition,
    ErrorHighlightDefinition,
    HighlightType,
    StoppedHighlightDefinition,
} from "../../debug/code-player/CodePlayer";
import { indentUnit } from "@codemirror/language";
import { codeContext } from "../../context/code-context/code-context";

interface CodeEditorProps {
    onRun: () => void;
    onStop: () => void;
    onPause: () => () => void;
    lineHighlighted: RunHighlightDefinition | ErrorHighlightDefinition | StoppedHighlightDefinition | null;
    isDebugging: boolean;
    isDebugLoading: boolean;
    isRunLoading: boolean;
    onSetTimeInterval: (interval: number) => void;
    problemId: string;
    problemStartingCode: string;
}

const CodeEditor: FC<CodeEditorProps> = (props) => {
    const codeCtx = useContext(codeContext);
    const [changed, setChanged] = useState(false);

    const { setCode } = codeCtx;
    useEffect(() => {
        setCode(props.problemStartingCode);
    }, [setCode, props.problemStartingCode]);

    const ref = useRef<ReactCodeMirrorRef>(null);

    const onChangeHandler = (val: string, _: any) => {
        setChanged(true);
        codeCtx.setCode(val);
    };

    let css_highlights = undefined;

    const line_count = codeCtx.code.split(/\r\n|\r|\n/).length;

    useEffect(() => {
        if (props.isDebugging) setChanged(false);
    }, [props.isDebugging]);

    if (!changed && props.lineHighlighted?.type === HighlightType.STOPPED) {
        css_highlights = `
            .CodeEditor .cm-line:nth-of-type(${props.lineHighlighted.lineNumber > line_count ? 1 : props.lineHighlighted.lineNumber}),
            .CodeEditor .cm-lineNumbers .cm-gutterElement:nth-of-type(${(props.lineHighlighted.lineNumber > line_count ? 1 : props.lineHighlighted.lineNumber) + 1}) {
                background-color: #444444;
            }
        `;

        if (props.lineHighlighted.exceptionMessage)
            css_highlights += `
                .CodeEditor .cm-line:nth-of-type(${props.lineHighlighted.lineNumber > line_count ? 1 : props.lineHighlighted.lineNumber})::after {
                    content: "${props.lineHighlighted.exceptionMessage}";
                    color: #ff0028;
                    margin: 0 1em;
                }
            `;
    }

    if (props.lineHighlighted?.type === HighlightType.RUN)
        css_highlights = `
        .CodeEditor .cm-line:nth-of-type(${props.lineHighlighted.lineNumber > line_count ? 1 : props.lineHighlighted.lineNumber}),
        .CodeEditor .cm-lineNumbers .cm-gutterElement:nth-of-type(${(props.lineHighlighted.lineNumber > line_count ? 1 : props.lineHighlighted.lineNumber) + 1}) {
            background-color: #1537b866;
        }`;

    if (props.lineHighlighted?.type === HighlightType.ERROR)
        css_highlights = `
        .CodeEditor .cm-line:nth-of-type(${props.lineHighlighted.lineNumber > line_count ? 1 : props.lineHighlighted.lineNumber}),
        .CodeEditor .cm-lineNumbers .cm-gutterElement:nth-of-type(${(props.lineHighlighted.lineNumber > line_count ? 1 : props.lineHighlighted.lineNumber) + 1}) {
            background-color: #86002a;
        }

        .CodeEditor .cm-line:nth-of-type(${props.lineHighlighted.lineNumber > line_count ? 1 : props.lineHighlighted.lineNumber})::after {
            content: "${props.lineHighlighted.message}";
            color: #ffffff;
            margin: 0 1em;
        }

        `;

    const css_base = `
        .CodeEditor {
            background-color: inherit;
        }
        .CodeEditor .cm-foldGutter {
            display: none !important;
        }
    `;

    return (
        <div className={styles.CodeEditorWrapper}>
            <CodeMirror
                key={props.problemId}
                theme={vscodeDark}
                ref={ref}
                className={`${styles.CodeEditor} CodeEditor`}
                height={"100%"}
                extensions={[python(), indentUnit.of("    ")]}
                value={codeCtx.code}
                onChange={onChangeHandler}
                editable={!props.isDebugging}
            />
            {/* <Navbar
                isDebugLoading={props.isDebugLoading}
                isRunLoading={props.isRunLoading}
                onRun={onRunHandler}
                onStop={props.onStop}
                onPause={props.onPause}
                isDebugging={props.isDebugging}
                className={styles.Navbar}
                onSetTimeInterval={props.onSetTimeInterval}
            /> */}
            <style>{css_highlights}</style>
            <style>{css_base}</style>
        </div>
    );
};

export default CodeEditor;
