import PlayPlan, { PlayPlanAction, VariablesState } from "../interfaces/PlayPlanInterface";
import PlayPlanSubscriberInterface from "../interfaces/PlayPlanSubscriberInterface";
import TimestampListenerInterface from "../interfaces/TimestampListenerInterface";
import VariablesStore from "./VariablesStore";

class VariablesDisplay implements PlayPlanSubscriberInterface, TimestampListenerInterface {
    private _pointer: number;
    private _variableStore: VariablesStore;
    private _variableSetter?: (variables: VariablesState | undefined) => void;

    constructor(variable_store: VariablesStore) {
        this._variableStore = variable_store;
        this._pointer = 0;
    }

    public setTimestamp(timestamp: number): void {
        const variables = this._variableStore.compile(timestamp);
        this._variableSetter?.(variables);
    }

    public setVariableSetter(variableSetter: (variables: VariablesState | undefined) => void) {
        this._variableSetter = variableSetter;
    }

    public reset() {
        this._variableStore.reset();
        this._pointer = 0;
        this._variableSetter?.(undefined);
    }

    public notify(plan: PlayPlan): void {
        if (this._pointer >= plan.length) return;
        this.digest(plan.slice(this._pointer));
        this._pointer = plan.length;
    }

    private digest(plan: PlayPlanAction[]) {
        for (const action of plan) {
            if (action.type !== "variable_changes") continue;
            this._variableStore.add_change(action.time_stamp, action.variable_changes);
        }
    }
}

export default VariablesDisplay;
