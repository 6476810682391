import { Problem } from "../interfaces";

class ProblemsMiddleware {
    private _host: string;

    public constructor(host: string) {
        this._host = host;
    }
    public async getProblems() {
        const response = await fetch(`${this._host}/problems`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        });

        const data = (await response.json()) as Problem[];
        return data;
    }

    public async getProblem(problemId: string) {
        const response = await fetch(`${this._host}/problems/${problemId}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        });

        const data = (await response.json()) as Problem;
        return data;
    }
}

export default ProblemsMiddleware;
