import React from "react";
import { createRoot } from "react-dom/client";
// import App from "./App";
import "./index.css";
import CodeContextProvider from "./context/code-context/CodeContextProvider";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ProblemPage from "./pages/ProblemPage/ProblemPage";

const container = document.getElementById("root")!;
const root = createRoot(container);

const router = createBrowserRouter([
    {
        path: "/problems/:problemId",
        element: <ProblemPage />,
    },
]);

root.render(
    <React.StrictMode>
        <CodeContextProvider>
            <RouterProvider router={router} />

            {/* <App /> */}
        </CodeContextProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
