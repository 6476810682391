import { VisualizationAction, PlayPlanAction } from "../interfaces/PlayPlanInterface";
import SnapshotsKeeper from "./SnapshotsKeeper";
import StructureClient from "./structure-clients/StructureClient";
import PlayPlanSubscriberInterface from "../interfaces/PlayPlanSubscriberInterface";
import TimestampListenerInterface from "../interfaces/TimestampListenerInterface";

class StructureKeeper implements PlayPlanSubscriberInterface, TimestampListenerInterface {
    private _structure_clients: Record<number, StructureClient>;
    private _pointer: number;

    constructor() {
        this._structure_clients = {};
        this._pointer = 0;
    }

    public reset() {
        this._structure_clients = {};
        this._pointer = 0;
    }

    public notify(plan: PlayPlanAction[]) {
        if (this._pointer >= plan.length) return;
        this.digest(plan.slice(this._pointer));
        this._pointer = plan.length;
    }

    public setTimestamp(time_stamp: number) {
        for (const structure_client in this._structure_clients) {
            this._structure_clients[structure_client].setTimeStamp(time_stamp);
        }
    }

    private digest(plan: PlayPlanAction[]) {
        for (const action of plan) {
            if (action.type !== "visualization") continue;
            if (!(action.id in this._structure_clients)) {
                this.create(action);
            }
            this._structure_clients[action.id].notify(action);
        }
    }

    private create(action: VisualizationAction) {
        const client = new StructureClient(action.id, new SnapshotsKeeper());
        if (!client.getVisualStateSetter()) {
            this.dispatchStructureCreationEvent(client);
        }
        this.register(client);
    }

    private register(client: StructureClient) {
        this._structure_clients[client.id] = client;
    }

    private dispatchStructureCreationEvent(client: StructureClient) {
        window.dispatchEvent(
            new CustomEvent<StructureClient>("structureCreation", {
                detail: client,
                bubbles: false,
            }),
        );
    }
}

export default StructureKeeper;
