import { FC, useRef, useState } from "react";
import styles from "./DebuggingControls.module.scss";
import Button from "../../UI/Button/Button";

interface DebuggingControlsProps {
    isPlaying: boolean;
    onBack: () => void;
    onPause: () => () => void;
    onStop: () => void;
}

const DebuggingControls: FC<DebuggingControlsProps> = (props) => {
    const resume_handler_ref = useRef<{ resume: undefined | (() => void) }>({ resume: undefined });
    const [paused, setPaused] = useState(false);

    const pauseHandler = () => {
        setPaused(true);
        const resume = props.onPause();

        resume_handler_ref.current.resume = () => {
            setPaused(false);
            resume();
        };
    };
    return (
        <div className={styles.DebuggingControls}>
            <Button onClick={props.onBack}>⏴︎ Back</Button>
            {!paused && <Button onClick={pauseHandler}>⏸︎ Pause</Button>}
            {paused && <Button onClick={resume_handler_ref.current.resume}>► Play</Button>}
            <Button onClick={props.onStop}>⏹ Stop</Button>
            {/* {!props.isPlaying && <Button onClick={props.onClear}>Clear</Button>} */}

            {/* <Button>Forward</Button> */}
        </div>
    );
};

export default DebuggingControls;
