import { FC, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import "react-reflex/styles.css";
import { useParams } from "react-router";
import CodeEditor from "../../components/CodeEditor/CodeEditor";
import Debug from "../../components/Debug/Debug";
import ClearButton from "../../components/Debug/DebuggingControls/ClearButton";
import DebuggingControls from "../../components/Debug/DebuggingControls/DebuggingControls";
import ProblemDescription from "../../components/Problem/ProblemDescription/ProblemDescription";
import Problems from "../../components/Problem/Problems/Problems";
import RunButton from "../../components/RunButton/RunButton";
import Tabs from "../../components/Tabs/Tabs";
import TestCases from "../../components/TestCases/TestCases";
import TestResults from "../../components/TestResults/TestResults";
import { useDebug } from "../../hooks/useDebug";
import { useProblems } from "../../hooks/useProblems";
import { useRun } from "../../hooks/useRun";
import { TabsLeft, TabsRight } from "../../types/types";
import styles from "./ProblemPage.module.scss";

interface ProblemPageProps {}

const ProblemPage: FC<ProblemPageProps> = (props) => {
    const { problemId } = useParams();
    const { problems, selectProblem, selectedProblem } = useProblems();

    const [selectedTabLeft, setSelectedTabLeft] = useState<TabsLeft>(TabsLeft.PROBLEM);
    const [selectedTabRight, setSelectedTabRight] = useState<TabsRight>(TabsRight.TEST_CASES);

    const debug = useDebug();
    const run = useRun();

    const [isRunLoading, setIsRunLoading] = useState(false);

    const onRun = () => {
        setIsRunLoading(true);
    };

    useEffect(() => {
        if (problemId) selectProblem(problemId);
    }, [problemId, selectProblem]);

    const { resetTestResults } = run;
    const { clear } = debug;
    useEffect(() => {
        resetTestResults();
        clear();
    }, [resetTestResults, problemId, clear]);

    useEffect(() => {
        setSelectedTabLeft(TabsLeft.PROBLEM);
    }, [selectedProblem?.id]);

    return (
        <div className={styles.ProblemPage}>
            <ReflexContainer orientation="vertical">
                <ReflexElement>
                    <div className={styles.Pane}>
                        <div className={styles.LeftPaneContent}>
                            <Problems problems={problems} selectedProblemId={selectedProblem?.id} />
                            <Tabs
                                className={styles.TabsLeftPane}
                                selectedTab={selectedTabLeft}
                                onSelectedTabChange={setSelectedTabLeft}
                                tabs={Object.values(TabsLeft)}
                            >
                                {selectedTabLeft === TabsLeft.DEBUG && (
                                    <Debug
                                        refresh={debug.refresh}
                                        onClientAdd={debug.onClientAdd}
                                        structureClients={debug.structureClients}
                                        variables={debug.variables}
                                    />
                                )}

                                {selectedTabLeft === TabsLeft.PROBLEM && selectedProblem && (
                                    <ProblemDescription
                                        name={selectedProblem.name}
                                        description={selectedProblem.description}
                                    />
                                )}
                            </Tabs>
                        </div>
                    </div>
                </ReflexElement>

                <ReflexSplitter className={styles.Splitter} />

                <ReflexElement>
                    <div className={`${styles.Pane}`}>
                        <ReflexContainer orientation="horizontal">
                            <ReflexElement>
                                {selectedProblem && (
                                    <CodeEditor
                                        problemId={selectedProblem.id}
                                        problemStartingCode={selectedProblem.starting_code}
                                        isRunLoading={isRunLoading}
                                        isDebugLoading={debug.isLoading}
                                        onRun={onRun}
                                        onStop={debug.stop}
                                        onPause={debug.pause}
                                        isDebugging={debug.isRunning}
                                        lineHighlighted={debug.lineHighlighted}
                                        onSetTimeInterval={debug.setTimeInterval}
                                    />
                                )}
                                {debug.isRunning && (
                                    <DebuggingControls
                                        onBack={() => {}}
                                        onPause={debug.pause}
                                        onStop={debug.stop}
                                        isPlaying={debug.isRunning}
                                    />
                                )}

                                {!debug.isClear && !debug.isRunning && <ClearButton onClear={debug.clear} />}
                            </ReflexElement>
                            <ReflexSplitter className={styles.Splitter} />
                            <ReflexElement style={{ overflowX: "hidden" }}>
                                <Tabs
                                    selectedTab={selectedTabRight}
                                    onSelectedTabChange={setSelectedTabRight}
                                    tabs={Object.values(TabsRight)}
                                >
                                    {selectedProblem && selectedTabRight === TabsRight.TEST_CASES && (
                                        <TestCases
                                            isDebugLoading={debug.isLoading}
                                            isDebugging={debug.isRunning}
                                            onDebug={(inputs) => {
                                                setSelectedTabLeft(TabsLeft.DEBUG);
                                                debug.debug(inputs, selectedProblem);
                                            }}
                                            selectedProblem={selectedProblem}
                                        />
                                    )}
                                    {selectedProblem && selectedTabRight === TabsRight.TEST_RESULTS && (
                                        <TestResults
                                            testResults={run.testCaseResults}
                                            isDebugLoading={debug.isLoading}
                                            isDebugging={debug.isRunning}
                                            onDebug={(inputs) => {
                                                setSelectedTabLeft(TabsLeft.DEBUG);
                                                debug.debug(inputs, selectedProblem);
                                            }}
                                            testCases={selectedProblem.test_cases}
                                        />
                                    )}
                                </Tabs>
                            </ReflexElement>
                        </ReflexContainer>
                    </div>
                </ReflexElement>
            </ReflexContainer>
            {!debug.isRunning &&
                selectedProblem &&
                ReactDOM.createPortal(
                    <RunButton
                        onRun={() => {
                            run.run(selectedProblem);
                            setSelectedTabRight(TabsRight.TEST_RESULTS);
                        }}
                        isLoading={false}
                    />,
                    document.getElementById("run-button-root")!,
                )}

            {/* <ProblemMenu selectedProblem={problem} onProblemChange={setProblem} /> */}
        </div>
    );
};

export default ProblemPage;
