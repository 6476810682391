export const RATIO = 2;

class VisualizerHelper {
    public static update(
        content_setter: React.Dispatch<React.SetStateAction<React.ReactNode>>,
        content: string | undefined,
    ) {
        content_setter(
            content ? (
                <div
                    id="outer"
                    style={{
                        fontSize: "16px",
                        color: "white",
                        fontFamily: "monospace",
                        borderRadius: "1em",
                        // backgroundColor: "#222430",
                        padding: "0.5em",
                        width: "fit-content",
                        height: "fit-content",
                    }}
                    dangerouslySetInnerHTML={{ __html: content ?? "" }}
                ></div>
            ) : undefined,
        );

        setTimeout(this._setupObjectHover);
    }

    // private static

    public static _setupObjectHover() {
        const objectTags = document.getElementsByClassName("object-tag");

        for (let i = 0; i < objectTags.length; i++) {
            // Add mouseover event
            objectTags[i].addEventListener<any>("mouseover", mouseOverEventHandler);

            // Add mouseout event to undo the hover effect
            objectTags[i].addEventListener<any>("mouseout", mouseOutEventHandler);
        }
    }
}

function mouseOverEventHandler(this: Element) {
    (this as any).style.backgroundColor = "white"; // Example effect
    (this as any).style.color = "black"; // Example effect

    const objects = document.querySelectorAll(`#o${this.id}`);
    for (let i = 0; i < objects.length; i++) {
        (objects[i] as any).style.backgroundColor = "white"; // Example effect
        (objects[i] as any).style.color = "black"; // Example effect
    }
}

function mouseOutEventHandler(this: Element) {
    (this as any).style.backgroundColor = ""; // Example effect
    (this as any).style.color = ""; // Example effect
    const objects = document.querySelectorAll(`#o${this.id}`);
    for (let i = 0; i < objects.length; i++) {
        (objects[i] as any).style.color = ""; // Example effect
        (objects[i] as any).style.backgroundColor = "black"; // Example effect
    }
}

export default VisualizerHelper;
