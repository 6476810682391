import { useCallback, useContext, useEffect, useState } from "react";
import CodePlayer, {
    ErrorHighlightDefinition,
    RunHighlightDefinition,
    StoppedHighlightDefinition,
} from "../debug/code-player/CodePlayer";
import TimestampDispatcher from "../debug/code-player/TimestampDispatcher";
import ConnectionHandler from "../debug/connection-handler/ConnectionHandler";
import PlanPublisher from "../debug/plan-publisher/PlanPublisher";
import StructureKeeper from "../debug/structures/StructureKeeper";
import VariablesDisplay from "../debug/variables-display/VariablesDisplay";
import VariablesStore from "../debug/variables-display/VariablesStore";
import { VariablesState } from "../debug/interfaces/PlayPlanInterface";
import StructureClient from "../debug/structures/structure-clients/StructureClient";
import { codeContext } from "../context/code-context/code-context";
import { TestCase } from "../types/types";
import { BACKEND_URL } from "../constants";
import { Problem } from "../problems/interfaces";

const planPublisher = new PlanPublisher();
const structureKeeper = new StructureKeeper();
const variablesDisplay = new VariablesDisplay(new VariablesStore());

const initSpeed = 1000;

const timestampDispatcher = new TimestampDispatcher();
timestampDispatcher.addListener(structureKeeper);
timestampDispatcher.addListener(variablesDisplay);

const codePlayer = new CodePlayer(timestampDispatcher, initSpeed);

planPublisher.registerSubscriber(structureKeeper);
planPublisher.registerSubscriber(variablesDisplay);
planPublisher.registerSubscriber(codePlayer);

// const connectionHandler = new ConnectionHandler(planPublisher, "http://coderun.leetdebug.com");
const connectionHandler = new ConnectionHandler(planPublisher, BACKEND_URL);

export const useDebug = () => {
    const codeCtx = useContext(codeContext);

    const [variables, setVariables] = useState<VariablesState | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [isClear, setIsClear] = useState(true);
    const [isRunning, setIsRunning] = useState(false);
    const [structureClients, setStructureClients] = useState<StructureClient[]>([]);

    const [lineHighlighted, setLineHighlighted] = useState<
        RunHighlightDefinition | ErrorHighlightDefinition | StoppedHighlightDefinition | null
    >(null);

    useEffect(() => {
        connectionHandler.setIsLoadingSetter(setIsLoading);
        variablesDisplay.setVariableSetter(setVariables);
        codePlayer.setLineHighlighter(setLineHighlighted);

        codePlayer.setRunningStopper(() => {
            setIsRunning(false);
        });
        connectionHandler.setRunningStopper(() => {
            setIsRunning(false);
        });
    }, []);

    const line_count = codeCtx.code.split(/\r\n|\r|\n/).length;

    useEffect(() => {
        codePlayer.setLineCount(line_count);
    }, [line_count]);

    const onClientAdd = useCallback((client: StructureClient) => {
        setStructureClients((prevClients) => [client, ...prevClients]);
    }, []);

    const refresh = useCallback(() => {
        codePlayer.refresh();
    }, []);

    const debug = useCallback(
        (testCase: TestCase, problem: Problem) => {
            setLineHighlighted(null);
            connectionHandler.send({ code: codeCtx.code, test_case: testCase.inputs, problem: problem.id });
            variablesDisplay.reset();
            planPublisher.reset();
            structureKeeper.reset();
            codePlayer.reset();
            setStructureClients([]);
            setIsRunning(true);
            setIsClear(false);
        },
        [codeCtx.code],
    );

    const clear = useCallback(() => {
        setLineHighlighted(null);
        variablesDisplay.reset();
        setStructureClients([]);
        setIsClear(true);
    }, []);

    const stop = useCallback(() => {
        codePlayer.stop();
        setIsRunning(false);
    }, []);

    const setTimeInterval = useCallback((interval: number) => {
        codePlayer.timeInterval = interval;
    }, []);

    const pause = useCallback(() => {
        return codePlayer.pause();
    }, []);

    return {
        variables,
        isLoading,
        isRunning,
        structureClients,
        lineHighlighted,
        onClientAdd,
        debug,
        stop,
        setTimeInterval,
        pause,
        clear,
        isClear,
        refresh,
    };
};
