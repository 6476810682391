import React from "react";
import styles from "./Tabs.module.scss";
import Button from "../UI/Button/Button";
import { TabsLeft, TabsRight } from "../../types/types";

interface TabsProps<Tabs> {
    tabs: Tabs[];
    selectedTab: Tabs;
    onSelectedTabChange: (tab: Tabs) => void;
    children: React.ReactNode;
    className?: string;
}

function Tabs<T extends TabsLeft | TabsRight>(props: TabsProps<T>) {
    return (
        <div className={`${styles.Tabs} ${props.className || ""}`}>
            <div className={styles.TabContent}>{props.children}</div>
            <div className={styles.TabsNav}>
                {props.tabs.map((tab) => (
                    <Button
                        className={`${styles.Tab} ${props.selectedTab === tab ? styles.Selected : ""}`}
                        onClick={() => {
                            props.onSelectedTabChange(tab);
                        }}
                    >
                        {tab}
                    </Button>
                ))}
            </div>
        </div>
    );
}

export default Tabs;
