import { createContext } from "react";

interface CodeContext {
    code: string;
    setCode: (code: string) => void;
}

export const codeContext = createContext<CodeContext>({
    code: "",
    setCode: () => {},
});
