import { FC, useState } from "react";
import styles from "./Problems.module.scss";
import { ProblemDifficulties } from "../../../types/types";
import { Problem } from "../../../problems/interfaces";
import { Link } from "react-router-dom";

interface ProblemsProps {
    selectedProblemId?: string;
    problems: Problem[];
}

const Problems: FC<ProblemsProps> = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    console.log("problems", props.problems, props.selectedProblemId);
    return (
        <div
            onMouseOver={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
            className={`${styles.Problems} ${isOpen ? styles.Open : ""}`}
        >
            <div className={`${styles.Buttons} ${isOpen ? styles.Open : ""}`}>
                {props.problems.map((problem, idx) => (
                    <Link
                        key={problem.id}
                        to={`/problems/${problem.id}`}
                        className={`${styles.ProblemButton} ${props.selectedProblemId === problem.id ? styles.Selected : ""}`}
                    >
                        <span className={styles.ProblemNumber}>{idx + 1}</span>

                        <div className={`${styles.ProblemNameWrapper} ${isOpen ? styles.Open : ""}`}>
                            <span className={styles.ProblemName}>{problem.name}</span>
                            {problem.difficulty === ProblemDifficulties.EASY && (
                                <span className={`${styles.ProblemDifficulty} ${styles.Easy}`}>
                                    {problem.difficulty}
                                </span>
                            )}
                            {problem.difficulty === ProblemDifficulties.MEDIUM && (
                                <span className={`${styles.ProblemDifficulty} ${styles.Medium}`}>
                                    {problem.difficulty}
                                </span>
                            )}
                            {problem.difficulty === ProblemDifficulties.HARD && (
                                <span className={`${styles.ProblemDifficulty} ${styles.Hard}`}>
                                    {problem.difficulty}
                                </span>
                            )}
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default Problems;
