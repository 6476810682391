import VisualizerHelper from "../../visualizer-helper/VisualizerHelper";
import StructureClientInterface from "../../interfaces/StructureClientInterface";
import SnapshotsKeeper from "../SnapshotsKeeper";
import { PlayPlanAction, VisualizationAction } from "../../interfaces/PlayPlanInterface";

export type handlerMethod = (plan: PlayPlanAction) => void;

class StructureClient implements StructureClientInterface {
    private _id: number;
    private _snapshot_keeper?: SnapshotsKeeper;
    private _visual_state_setter: React.Dispatch<React.SetStateAction<React.ReactNode>> | undefined;

    constructor(
        id: number,
        snapshot_keeper: SnapshotsKeeper | undefined,
        visual_state_setter?: React.Dispatch<React.SetStateAction<React.ReactNode>>,
    ) {
        this._id = id;
        this._snapshot_keeper = snapshot_keeper;
        this._visual_state_setter = visual_state_setter;
    }

    public get id(): number {
        return this._id;
    }

    public notify(frame: VisualizationAction) {
        this.takeSnapshot(frame);
    }

    public takeSnapshot(frame: VisualizationAction): void {
        this._snapshot_keeper?.addSnapshot({
            snapshot: frame.html,
            time_stamp: frame.time_stamp,
        });
    }

    public setVisualStateSetter(visual_state_setter: React.Dispatch<React.SetStateAction<React.ReactNode>>): void {
        this._visual_state_setter = visual_state_setter;
    }

    public getVisualStateSetter() {
        return this._visual_state_setter;
    }

    public setTimeStamp(time_stamp: number): void {
        if (!this._snapshot_keeper || !this._visual_state_setter) {
            return;
        }
        const content = this._snapshot_keeper.getSnapshot(time_stamp)?.snapshot;
        VisualizerHelper.update(this._visual_state_setter, content);
    }
}

export default StructureClient;
