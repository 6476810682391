export type id = number;
declare module "*.scss";

export type TestCase = { id: number; inputs: { [varName: string]: any } };

export enum TabsLeft {
    PROBLEM = "Problem",
    DEBUG = "Debugging",
}

export enum TabsRight {
    TEST_CASES = "Test cases",
    TEST_RESULTS = "Test Results",
}

export enum ProblemDifficulties {
    EASY = "EASY",
    MEDIUM = "MEDIUM",
    HARD = "HARD",
}
