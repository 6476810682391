import { FC } from "react";
import styles from "./DebuggingControls.module.scss";
import Button from "../../UI/Button/Button";

interface ClearButtonProps {
    onClear: () => void;
}

const ClearButton: FC<ClearButtonProps> = (props) => {
    return (
        <div className={styles.DebuggingControls}>
            <Button onClick={props.onClear}>Clear</Button>
        </div>
    );
};

export default ClearButton;
