interface Snapshot {
    time_stamp: number;
    snapshot: string;
}

class SnapshotsKeeper {
    private _last_updates: { [time_stamp: number]: Snapshot | undefined };
    private _last_update: number;

    constructor() {
        this._last_updates = {};
        this._last_update = -1;
    }

    public addSnapshot(snapshot: Snapshot) {
        const prev_snapshot = this._last_updates[this._last_update];
        let temp = this._last_update + 1;

        while (temp < snapshot.time_stamp) {
            this._last_updates[temp++] = prev_snapshot;
        }
        this._last_updates[snapshot.time_stamp] = snapshot;
        this._last_update = snapshot.time_stamp;
    }

    public getSnapshot(time_stamp: number) {
        return this._last_updates[Math.min(time_stamp, this._last_update)];
    }
}

export default SnapshotsKeeper;
