import { PlayPlanAction, LineExecutedAction, ExceptionAction } from "../interfaces/PlayPlanInterface";
// import { ErrorResponse } from "../interfaces/Response";
import PlayPlanSubscriberInterface from "../interfaces/PlayPlanSubscriberInterface";
import TimestampDispatcher from "./TimestampDispatcher";

export enum HighlightType {
    RUN,
    ERROR,
    STOPPED,
}

export interface StoppedHighlightDefinition {
    lineNumber: number;
    type: HighlightType.STOPPED;
    exceptionMessage?: string;
}

export interface RunHighlightDefinition {
    lineNumber: number;
    type: HighlightType.RUN;
}

export interface ErrorHighlightDefinition {
    lineNumber: number;
    type: HighlightType.ERROR;
    message: string;
}

class CodePlayer implements PlayPlanSubscriberInterface {
    private _pointer: number;
    private _plan: PlayPlanAction[];

    private _dispatcher: TimestampDispatcher;
    private _timeInterval: number;
    private _lineHighlighter?: (
        line: StoppedHighlightDefinition | RunHighlightDefinition | ErrorHighlightDefinition | null,
    ) => void;
    private _runningStopper?: () => void;
    private _timeout?: NodeJS.Timeout;
    private _lineCount?: number;
    private _lineNumber?: number;
    private _timestamp?: number;
    private _exceptionMessage?: string;

    constructor(dispatcher: TimestampDispatcher, timeInterval: number) {
        this._pointer = 0;
        this._plan = [];
        this._dispatcher = dispatcher;
        this._timeInterval = timeInterval;
    }

    public setLineCount(line_count: number) {
        this._lineCount = line_count;
    }

    public reset() {
        this._pointer = 0;
        this._plan = [];
        this._exceptionMessage = undefined;
        // this._timestamp = undefined;
        this._lineNumber = undefined;
        // this._lineCount = undefined;
    }

    public setLineHighlighter(
        lineHighlighter: (
            line: StoppedHighlightDefinition | RunHighlightDefinition | ErrorHighlightDefinition | null,
        ) => void,
    ) {
        this._lineHighlighter = lineHighlighter;
    }

    public setRunningStopper(running_stopper: () => void) {
        this._runningStopper = running_stopper;
    }

    public get timeInterval(): number {
        return this._timeInterval;
    }

    public set timeInterval(interval: number) {
        this._timeInterval = interval * 1000;
    }

    public notify(plan: PlayPlanAction[]) {
        this._plan = [...this._plan, ...plan];
        this.startPlay();
    }

    public correctPlayStartPoint() {
        let action: PlayPlanAction | undefined = undefined;
        while (true) {
            action = this._plan[this._pointer];

            if (!action || action.type !== "exception") this._pointer++;

            if (
                !action ||
                (action.type === "line_executed" && action.line_number > (this._lineCount || 0)) ||
                action.type === "exception"
            )
                break;
        }
    }

    public refresh() {
        if (this._timestamp) this._dispatcher.setTimestamp(this._timestamp);
    }

    public startPlay() {
        this._pointer = 0;
        this._lineHighlighter?.(null);
        const interval = this.timeInterval;
        this.correctPlayStartPoint();
        // Skip to first line greater than the
        this._timeout = setTimeout(this._run.bind(this), interval);
    }

    private _run() {
        let action: PlayPlanAction | undefined = undefined;
        let timestamp: number | undefined = undefined;
        const interval = this.timeInterval;

        while (!this.isDone()) {
            action = this._plan[this._pointer];
            if (action.type !== "line_executed" || (timestamp !== undefined && timestamp !== action.time_stamp)) break;

            timestamp = action.time_stamp;
            this._handleLineExecutedAction(action);
            this._pointer++;

            // if (this._last_line_flag) return setTimeout(this.stop.bind(this), interval);
        }

        while (!this.isDone()) {
            action = this._plan[this._pointer];
            if (
                (action.type !== "variable_changes" && action.type !== "visualization") ||
                (timestamp !== undefined && timestamp !== action.time_stamp)
            )
                break;

            timestamp = action.time_stamp;

            this._dispatcher.setTimestamp(timestamp);
            this._pointer++;
        }

        while (!this.isDone()) {
            action = this._plan[this._pointer];

            if (action.type !== "exception" || (timestamp !== undefined && timestamp !== action.time_stamp)) {
                break;
            }

            timestamp = action.time_stamp;
            this._handleExceptionAction(action);
            this._pointer++;
        }

        this._timestamp = timestamp;

        if (this.isDone()) return setTimeout(this.stop.bind(this), interval);

        this._timeout = setTimeout(this._run.bind(this), interval);
    }

    private _handleExceptionAction(action: ExceptionAction) {
        this._lineNumber = action.line_number;
        this._exceptionMessage = action.message;
        this._lineHighlighter?.({
            lineNumber: action.line_number,
            message: action.message,
            type: HighlightType.ERROR,
        });
    }

    private _handleLineExecutedAction(action: LineExecutedAction) {
        this._lineNumber = action.line_number;
        this._lineHighlighter?.({ lineNumber: action.line_number, type: HighlightType.RUN });
    }

    public stop() {
        this._runningStopper?.();
        if (this._lineNumber)
            this._lineHighlighter?.({
                lineNumber: this._lineNumber,
                type: HighlightType.STOPPED,
                exceptionMessage: this._exceptionMessage,
            });
        this._timeout && clearTimeout(this._timeout);
    }

    public pause() {
        this._timeout && clearTimeout(this._timeout);

        return () => {
            this._timeout = setTimeout(() => {
                this._run();
            }, this._timeInterval);
        };
    }

    public isDone() {
        const last_action = this._plan[this._plan.length - 1];
        if (!last_action || !this._plan[this._pointer]) {
            return true;
        }

        if (this._plan[this._pointer].time_stamp === last_action.time_stamp && last_action.type !== "exception") {
            return true;
        }
        return false;
        // if (this._plan[this._pointer].type === "line_executed") {
        //     let temp = this._pointer;
        //     while (temp < this._plan.length - 1) {
        //         if (this._plan[++temp].type === "line_executed") {
        //             return false;
        //         }
        //     }
        // }
        // return (
        //     this._pointer >= this._plan.length - 1 ||
        //     (this._plan[this._pointer].type === "line_executed" &&
        //         (this._plan[this._pointer] as LineExecutedAction).line_number > (this._lineCount || 0))
        // );
    }

    public hasNext() {
        return this._pointer < this._plan.length - 1;
    }
}

export default CodePlayer;
