import { FC } from "react";
import styles from "./ProblemDescription.module.scss";

interface ProblemDescriptionProps {
    name: string;
    description: string;
}

const ProblemDescription: FC<ProblemDescriptionProps> = (props) => {
    return (
        <div className={styles.Wrapper}>
            <div className={styles.ProblemDescription}>
                <h1>{props.name}</h1>
                <p dangerouslySetInnerHTML={{ __html: props.description }}></p>
            </div>
        </div>
    );
};

export default ProblemDescription;
