import { FC, useRef, useState } from "react";
import styles from "./Variables.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import type {
    Object as ObjectType,
    Variables as VariablesType,
    Variable as VariableType,
} from "../../../debug/interfaces/PlayPlanInterface";

interface VariablesProps {
    variables: VariablesType | undefined;
}

const TAB_WIDTH = "1em";

const Variables: FC<VariablesProps> = ({ variables }) => {
    const variablesCache = useRef<{ [struct_id: number]: ObjectType }>({});
    const cancelerRef = useRef<(() => void) | null>(null);
    const variableClickHandler = (canceller: () => void) => {
        if (cancelerRef.current) cancelerRef.current();
        cancelerRef.current = canceller;
    };

    return (
        <div className={styles.Variables}>
            <div className={styles.Heading}>Variables</div>
            {!!variables && (
                <VariablesWrapper
                    variablesCache={variablesCache.current}
                    onVariableClick={variableClickHandler}
                    variables={variables}
                />
            )}
        </div>
    );
};

export default Variables;

interface VariablesWrapperProps {
    variables: VariablesType;
    onVariableClick: (canceller: () => void) => void;
    variablesCache: { [struct_id: number]: ObjectType };
    indent?: number;
}

function is_object(v: any) {
    return v != null && typeof v === "object";
}

const VariablesWrapper: FC<VariablesWrapperProps> = ({ variables, onVariableClick, variablesCache, indent }) => {
    return (
        <>
            {Object.keys(variables).map((variable_name) => (
                <Variable
                    variablesCache={variablesCache}
                    variable={variables[variable_name]}
                    variableName={variable_name}
                    onClick={onVariableClick}
                    indent={indent}
                />
            ))}
        </>
    );
};

interface VariableLineProps {
    variable: VariableType;
    indent?: number;
    variableName: string | null;
    onClick: (canceller: () => void) => void;
    variablesCache: { [struct_id: number]: ObjectType };
}

function clearSelection() {
    if (window.getSelection) {
        const sel = window.getSelection();
        sel?.removeAllRanges();
    }
}

const Variable: FC<VariableLineProps> = ({ variable, variableName, variablesCache, indent = 0, onClick }) => {
    if (variable != null && typeof variable === "object" && !("type" in variable)) {
        variablesCache[variable.id] = variable;
    }
    const [isHighlighted, setIsHighlighted] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const clickHandler = () => {
        onClick(() => setIsHighlighted(false));
        setIsHighlighted(true);
    };

    const doubleClickHandler: React.MouseEventHandler<HTMLDivElement> = (e) => {
        clearSelection();
        setIsOpen((prev) => !prev);
    };

    const chevronClickHandler = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <>
            <div
                className={`${styles.Variable} ${isHighlighted ? styles.Clicked : ""}`}
                key={variableName}
                onClick={clickHandler}
                onDoubleClick={doubleClickHandler}
            >
                <div style={{ width: `calc(${indent}*${TAB_WIDTH})` }}></div>
                <div
                    className={`${styles.Chevron} ${is_object(variable) ? styles.Show : ""} ${isOpen ? styles.Open : ""}`}
                    onClick={chevronClickHandler}
                >
                    <FontAwesomeIcon className={styles.Icon} icon={faChevronRight} />
                </div>

                {!variableName?.startsWith("__vuda_hidden") && (
                    <>
                        <div className={styles.VariableName}>{variableName}</div>
                        <div className={styles.Equals}>=</div>
                    </>
                )}

                {typeof variable === "string" && <div className={styles.String}>"{variable as string}"</div>}
                {typeof variable === "number" && <div className={styles.Number}>{variable as number}</div>}
                {typeof variable === "boolean" && (
                    <div className={styles.None}>{(variable as boolean) ? "True" : "False"}</div>
                )}
                {(variable === null || variable === undefined) && <div className={styles.None}>None</div>}

                {variable != null && typeof variable === "object" && !("type" in variable) && (
                    <div className={styles.Object}>{variable.obj_str}</div>
                )}

                {variable != null &&
                    typeof variable === "object" &&
                    "type" in variable &&
                    variable.type === "explored" && (
                        <div className={styles.Object}>{variablesCache[variable.id].obj_str}</div>
                    )}
            </div>

            {variable != null && typeof variable === "object" && !("type" in variable) && isOpen && (
                <VariablesWrapper
                    variables={variable.struct}
                    onVariableClick={onClick}
                    indent={indent + 1}
                    variablesCache={variablesCache}
                />
            )}
            {variable != null &&
                typeof variable === "object" &&
                "type" in variable &&
                variable.type === "explored" &&
                isOpen && (
                    <VariablesWrapper
                        variables={variablesCache[variable.id].struct}
                        onVariableClick={onClick}
                        indent={indent + 1}
                        variablesCache={variablesCache}
                    />
                )}
        </>
    );
};
