import TimestampListenerInterface from "../interfaces/TimestampListenerInterface";
import TimestampSetterInterface from "../interfaces/TimestampSetterInterface";

class TimestampDispatcher implements TimestampSetterInterface {
    private _listeners: TimestampListenerInterface[];

    public constructor() {
        this._listeners = [];
    }

    public addListener(listener: TimestampListenerInterface) {
        this._listeners.push(listener);
    }

    public setTimestamp(timestamp: number): void {
        for (const listener of this._listeners) {
            listener.setTimestamp(timestamp);
        }
    }
}

export default TimestampDispatcher;
