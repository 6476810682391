import React, { FC } from "react";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import VisualizerKeeper from "./Visualizer/VisualizerKeeper";
import Variables from "./Variables/Variables";
import styles from "./Debug.module.scss";
import type { Variables as VariablesType } from "../../debug/interfaces/PlayPlanInterface";
import StructureClient from "../../debug/structures/structure-clients/StructureClient";

interface DebugProps {
    variables: VariablesType | undefined;
    structureClients: StructureClient[];
    onClientAdd: (client: StructureClient) => void;
    refresh: () => void;
}

const Debug: FC<DebugProps> = (props) => {
    return (
        <ReflexContainer orientation="horizontal">
            <ReflexElement>
                <VisualizerKeeper
                    refresh={props.refresh}
                    onClientAdd={props.onClientAdd}
                    structureClients={props.structureClients}
                />
            </ReflexElement>
            <ReflexSplitter className={styles.Splitter} />
            <ReflexElement>
                <Variables variables={props.variables} />
            </ReflexElement>
        </ReflexContainer>
    );
};

export default Debug;
